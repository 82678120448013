import React from 'react'

const TikTaak = () => {

 

    return (
      <div className="bg-tik-pattern ticTacToe h-[390px] md:h-[370px] bg-center relative bg-no-repeat bg-cover rounded-3xl mt-10 flex md:flex-row flex-col items-center justify-start px-2 md:px-10">
      <img  src="/images/TIC TAC TOE@2x.png" alt="TIC TAK TOE" className='w-44 md:w-72 p-4 md:p-10 bg-[#2d79ff] bg-opacity-20 rounded-b-2xl md:rounded-t-2xl mt-0 md:mt-16 object-fill h-56 md:h-5/6'/>
       <div className='h-72 w-64 md:w-96 flex flex-col justify-center space-y-2 md:space-y-8 px-2 md:px-0 ml-0 md:ml-10 mt-0 md:mt-10'>
           <h1 className='font-bold text-xl text-center md:text-left md:text-4xl'>TIC TAC TOE</h1>
           <p className='text-sm md:text-lg text-center md:text-left'>A Fun puzzle game for your Android device to boost your online gaming experience </p>
         
           <button onClick={()=>{
            // window.ticTacToeDiv.onclick()
            window.location.href = 'https://play.rareprobgames.com/tictactoe/index.html';
          }}  className='ticTac bg-gradient-to-r  from-[#BB01EA] to-[#5E0175] md:w-72 w-44 mx-auto md:ml-0 p-2 md:p-4 px-0 md:px-10 rounded-xl md:rounded-[50px] text-xl md:text-4xl font-extrabold'>
            Play Now
           </button>
        
          
       </div>
       {/* <img src="/images//Cross copia@2x.png" alt="cross" className='absolute hidden md:block right-0 bottom-0 w-44'/> */}
     </div>
    )
  }

export default TikTaak
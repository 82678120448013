import React from 'react'


const FooterHub = () => {
    return (
      <div className='w-3/4 md:w-3/4 mt-20  mx-auto  '>
        <h1 className=' text-[20px]  md:text-[55px]  font-medium ml-4 md:ml-1  text-white mt-2 md:mt-10'>Join into our Hub</h1>
        <div className='grid grid-cols-2 gap-10 md:grid-cols-4   text-white justify-center  mt-4 md:mt-10'>
       
        <a href='https://www.facebook.com/rareprob_-103090149004368/' rel="noreferrer"  target={"_blank"} className='flex items-start justify-center space-x-4 cursor-pointer' >
                <img src="/images/icons/icons8-facebook.svg" alt="Facebook" className='w-8 md:w-10 hover:scale-125 transition-all'/>
                <div>
                <h1 className='text-sm md:text-xl font-medium text-white'>Facebook</h1>
               
                </div>
           
            </a>
            <a href='https://www.instagram.com/rareprob_' rel="noreferrer"  target={"_blank"} className='flex items-start justify-center space-x-4 cursor-pointer' >
              <img src="/images/icons/icons8-instagram-96.png" alt="Instagram" className='w-8 md:w-10 hover:scale-125 transition-all' />
              <div>
              <h1 className='text-sm md:text-xl font-medium text-white'>Instagram</h1>
              {/* <p className='text-xs'>@rareprob </p> */}
              </div>
          
           </a>
            <a href='https://www.linkedin.com/company/rareprob/' rel="noreferrer"  target={"_blank"} className='flex items-start justify-center cursor-pointer space-x-4' >
                <img src="/images/icons/icons8-linkedin-circled-96.svg" alt="LinkedIn" className='w-8 md:w-9 hover:scale-125 transition-all'/>
                <div className='cursor-pointer ' >
                  <h1 className='text-sm md:text-xl font-medium  text-white'>LinkedIn</h1>
                </div>
            </a>
            <a href="https://twitter.com/rareprob" rel="noreferrer" target={"_blank"}  className='flex items-start justify-center space-x-4 cursor-pointer '>
                <img src="/images/icons/icons8-twitter-96.png" alt="Twitter" className='w-8 md:w-10 hover:scale-125 transition-all'/>
                <div>
                <h1 className='text-sm md:text-xl font-medium text-white'>Twitter</h1>
              
                </div>
            </a>
            
          
           <a href='https://www.youtube.com/channel/UCNXZXijMtxueoWaNiye73AQ' rel="noreferrer"  target={"_blank"} className='flex items-start justify-center cursor-pointer space-x-4' >
                <img src="/images/icons/icons8-youtube.svg" alt="youtube" className='w-8 md:w-10 hover:scale-125 transition-all'/>
                <div className='cursor-pointer ' >
                  <h1 className='text-sm md:text-xl font-medium  text-white'>YouTube</h1>
                 
                </div>
            </a>
           <a href="https://in.pinterest.com/rareprob/" rel="noreferrer"  className='flex items-start justify-center cursor-pointer space-x-4' target={"_blank"}>
                <img src="/images/icons/icons8-pinterest.svg" alt="Pinterest" className='w-8 md:w-10 hover:scale-125 transition-all'/>
                <div className='cursor-pointer ' >
                  <h1 className='text-sm md:text-xl font-medium  text-white'>Pinterest</h1>
                  {/* <p className='text-xs'>@rareprob</p> */}
                </div>
            </a>
          
        </div>
       <div className='flex items-center justify-between  text-white px-4 md:px-10 mt-20 bg-white bg-opacity-5 h-16  rounded-lg '>
       <div className='flex items-center justify-center space-x-2 md:space-x-10'>
        <a href="https://rareprob.com/about-us">
          <h1  className="cursor-pointer  text-xs md:text-sm ">About us</h1>
        </a>
        
        <a href="https://rareprob.com/apps">
          <h1  className="cursor-pointer  text-xs md:text-sm ">Apps</h1>
        </a>
  
        <a href={"https://rareprob.com/blogs"}>
        <h1  className="cursor-pointer  text-xs md:text-sm ">Blogs</h1>
        
        </a>
     
     <a href={"https://rareprob.com/privacy-policy"}>
       <h1  className="cursor-pointer  text-xs md:text-sm ">Privacy</h1>
       
     </a>
     
     <a href={"https://rareprob.com/contact-us"}>
     <h1  className="cursor-pointer  text-xs md:text-sm ">Contact</h1>
     
     </a>
       </div>
      
       </div>
      </div>
    )
  }
  
  export default React.memo(FooterHub)
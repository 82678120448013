import './App.css';
import AllApps from './comp/AllApps';
import FindObject from './comp/FindObject';
import FlappyBall from './comp/FlappyBall';
import FooterHub from './comp/FooterHub';
import KnifeBall from './comp/KnifeBall';
import StackBall from './comp/StackBall';
import TikTaak from './comp/TikTaak';
import TwoZeroFourEight from './comp/TwoZeroFourEight';
import WaterSort from './comp/WaterSort';

function App() {

// useEffect(()=>{
//  window.aman.onclick()
// },[])

// TODO ads config here 


  return (
   <div className='bg-black h-full  text-white'>
    <header className='flex fixed z-40 w-full items-center justify-between px-2 lg:px-40 py-4 bg-gray-700 bg-opacity-90'>
      <img src="/images/icons/rrp_rrp logo.png" alt="rareprob" className='w-10 h-10'/>
      <div className='flex items-center text-xs lg:text-xl justify-center space-x-2 lg:space-x-10'>
       
        <a href='https://rareprob.com/about-us'>About </a>
        <a href='https://rareprob.com/contact-us'>Contact</a>
        <a href='https://rareprob.com/privacy-policy'>Privacy</a>
      </div>
    </header>
    <main className='md:mx-40 mx-10  pt-32'>
      <h1 className='text-3xl lg:text-5xl ml-8 font-bold text-left text-white'> Games</h1>
     <div className='mx-4 text-sm lg:mx-40'>
     </div>
     <TwoZeroFourEight/>
         <FlappyBall/>
         <WaterSort/>
         <KnifeBall/>
         <FindObject/>
         <StackBall/>
         <TikTaak/>
         <h1 className='text-[20px]  md:text-[50px] mt-20 font-medium'
         >Casual Games</h1>
         <div className='grid grid-cols-2 lg:grid-cols-3 ml-0 md:-ml-10 gap-10 md:gap-20  mt-10'>
            <a className='flex items-center flex-col justify-center' 
            href="https://play.rareprobgames.com/cube/index.html" >
              <img src="/images/Layer 1.png" alt="Rub" className='object-cover rounded-xl w-32 h-32 lg:w-64  lg:h-64'/>
              <h2 className='text-sm lg:text-2xl mt-2 font-bold'>Rubik's Cube</h2>
            <button
            className='bg-gradient-to-r  from-[#006FCE] to-[#0058BF] px-6 py-2 mt-2 font-medium rounded-xl'>Play Now
           </button>
            </a>
            <a  className='flex items-center flex-col justify-center'
            href="https://play.rareprobgames.com/memory-game/index.html">
            <img src="/images/gamesfolder/Screenshot 2023-01-20 at 2.40.40 PM copy.png" alt="Memory Game " 
            className='object-fill rounded-xl w-32 h-32 lg:w-64  lg:h-64'/>
            <h2 className='text-sm lg:text-2xl mt-2 font-bold'>Memory Game</h2>
            <button
            className='bg-gradient-to-r  from-[#006FCE] to-[#0058BF] px-6 py-2 mt-2 font-medium rounded-xl'>Play Now
           </button>
            </a>
          
            <a  className='flex items-center flex-col justify-center'
            href="https://play.rareprobgames.com/coloron/index.html">
            <img src="/images/Lacrosse Ball.H03.2k.png" alt="mountains " className='object-cover rounded-xl w-32 h-32  lg:w-64  lg:h-64'/>
            <h2 className='text-sm lg:text-2xl mt-2 font-bold'>Coloron</h2>
            <button
            className='bg-gradient-to-r  from-[#006FCE] to-[#0058BF] px-6 py-2 mt-2 font-medium rounded-xl'>Play Now
           </button>
            </a>
            <a  className='flex items-center flex-col justify-center'
            href="https://play.rareprobgames.com/tower_game/index.html">
            <img src="/images/gamesfolder/Screenshot 2023-01-20 at 2.42.12 PM copy.png" alt="Towe Game " className='object-cover rounded-xl w-32 h-32  lg:w-64  lg:h-64'/>
            <h2 className='text-sm lg:text-2xl mt-2 font-bold'>Tower Game</h2>
            <button
            className='bg-gradient-to-r  from-[#006FCE] to-[#0058BF] px-6 py-2 mt-2 font-medium rounded-xl'>Play Now
           </button>
            </a>
            
         </div>
         <AllApps/>
        
    </main>
    <footer>
      <FooterHub/>
    </footer>
   </div>
  
  );
}

export default App;

import React from 'react'

const KnifeBall = () => {
    return (
      <div className="bg-knife-pattern knifeBall h-[390px] md:h-[370px] bg-center relative bg-no-repeat bg-cover rounded-3xl mt-4 flex md:flex-row flex-col items-center justify-start px-2 md:px-10">
      
      <img  src="/images/knif.png" alt="Find The Difference" className='w-44 md:w-72 p-4 md:p-10 bg-[#8E5CBC] bg-opacity-50 rounded-b-2xl md:rounded-t-2xl mt-0 md:mt-16 object-fill h-56 md:h-5/6'/>
       <div className='h-72 w-64 md:w-96 flex flex-col justify-center space-y-2 md:space-y-8 px-2 md:px-0 ml-0 md:ml-10 mt-0 md:mt-10'>
           <h1 className='font-bold text-xl text-center md:text-left md:text-4xl'>Knife Throw</h1>
           <p className='text-sm md:text-lg text-center md:text-left'>Throw the Knife, hit the target and beat the bosses with single player online game</p>
         
             <button
             onClick={()=>{
            //  window.knifeBalldiv.onclick()
          
              window.location.href = 'https://play.rareprobgames.com/knifehits/index.html';
  
            }}
              className='findDiff bg-gradient-to-r  from-[#BB01EA] to-[#5E0175] w-44 md:w-72 mx-auto md:ml-0 p-2 md:p-4 px-0 md:px-10 rounded-xl md:rounded-[50px] text-xl md:text-4xl font-extrabold'>
              Play Now
            
             </button>
       </div>
   </div>
    )
  }
export default KnifeBall
import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AllApps = () => {

  

  
    const AppLinks=React.memo(function ChildComponent({ name,image,text,link,page }) {
    
   
     return (
     
         
     <div className='text-center z-10  backdrop-blur-[500px] hover:bg-opacity-30 hover:scale-105 transition-all bg-opacity-20 bg-white cursor-pointer text-white justify-center flex flex-col mt-10 h-[140px] md:h-[190px]  md:w-5/6 w-[100px]  hover:shadow-[0_0px_60px_1px_rgba(0,0,0,0.2)]  shadow-[0_0px_10px_6px_rgba(0,0,0,0.11)] md:pt-8 px-1 md:px-2 mx-1 md:mx-4 rounded-[14px] md:rounded-3xl' >
       
        <a href={page}>
             <img src={image} alt={name} className="object-fill w-16 h-16 md:w-[80px] md:h-[80px] rounded-xl  items-center mx-auto" loading={"lazy"}/>
             <h1 className='text-[12px] md:text-[14px] h-4 md:h-8 font-bold  mt-4'>{name}</h1>
             {/* <p className='text-xs mt-2 hidden md:block h-20 md:h-16'>{text}</p> */}
           
         </a>
         
        
     </div>
     )
   })
     
   
      const settings = {
       dots: true,
       infinite: true,
       speed: 500,
       slidesToShow:5,
       slidesToScroll: 1,
       responsive: [
         {
           breakpoint: 1024,
           settings: {
             slidesToShow: 3,
             slidesToScroll: 3,
             infinite: true,
             dots: true
           }
         },
         {
           breakpoint: 600,
           settings: {
             slidesToShow: 2,
             slidesToScroll: 2,
             initialSlide: 2
           }
         },
         {
           breakpoint: 480,
           settings: {
             slidesToShow: 2,
             slidesToScroll: 2
           }
         }
       ]
     };
   
     return (
       <div className=' mt-10  md:mt-20' id='apps'>
           <div className=' w-full  text-white  '>
           <h1 className='text-[20px]  md:text-[55px]  font-medium'>Our Products</h1>
           </div>
           <Slider {...settings} className="mx-10 md:mx-16 pb-10">
           <AppLinks 
                link={"https://play.google.com/store/apps/details?id=com.rocks.music.videoplayer&hl=en_IN&gl=US"}
                page={'https://rareprob.com/apps/rocks-video-player-app'}
                name={"Rocks Player"} 
                image={"images/icons/videoplayer.png"} 
                text="Video player (MP3 player) for all formats like mp3, mp4, MOV, etc. It gives the list of features to play videos and songs.             "/>
                      <AppLinks 
                      page={"https://rareprob.com/apps/file-manager-app"}
                      link={"https://play.google.com/store/apps/details?id=filemanager.files.fileexplorer.android.folder&hl=en_IN&gl=US"}
                      name={"File Manager"} 
                      image={"/images/icons/file mana.webp.png"} 
                      text="File manager app for managing files, doc, pdf files, and media content. File Manager is built with video hider functionality as Callock to hide your private photos & videos"/>
                      <AppLinks 
                      page={"https://rareprob.com/apps/video-to-mp3-converter-app"}
                      link={"https://play.google.com/store/apps/details?id=mp3converter.videotomp3.ringtonemaker&hl=en_IN&gl=US"}
                      name={"Video To Mp3"} 
                      image={"images/icons/vmp3.png"} 
                      text="You can trim and cut video files, merge audio, and convert video to mp3 with brand new features, Also can extract mp3 songs from HD and full HD videos.                   "/>
                               <AppLinks 
                      page={"https://rareprob.com/apps/gallery-photo-editor-and-collage-maker-app"}
                      link={"https://play.google.com/store/apps/details?id=com.rocks.photosgallery&hl=en_IN&gl=US"}
                      name={"Gallery"} 
                      image={"images/icons/gallery.png"} 
                      text="Photo Editor & Collage Maker App is free to edit photos with numerous filters, effects, stickers                   "/>
                      <AppLinks 
                      page={"https://rareprob.com/apps/music-player-mp4-mp3-player-app"}
                      link={"https://play.google.com/store/apps/details?id=com.rocks.music&hl=en_IN&gl=US"}
                      name={"Music Player"} 
                      image={"/images/icons/unnamed (1).webp"} 
                      text="This is a powerful and high-quality mp3 player and video player app which supports all formats of audio files and video files.
                      "/>
                      <AppLinks 
                      link={"https://play.google.com/store/apps/details?id=com.rocks.video.downloader&hl=en_IN&gl=US"}
                      page="https://rareprob.com/apps/video-downloader-files-hider-app"
                      name={"Video Downloader"} 
                      image={"/images/icons/videoDownloader.png"} 
                      text="Video Downloader is a user-friendly application for downloading every social media video & photo along with a hassle-free experience.. 
                      "/>
                       <AppLinks 
                      page={"https://tinyurl.com/Rareprob-Find-difference"}
                      link={true}
                      name={"Find Difference"} 
                      image={"/images/icons/game.png"} 
                      text="Find Differences is an perfect game app to boost your IQ and enhance your relaxing experience after a long tired day. "
                      />
                       <AppLinks 
                      page={'https://rareprob.com/apps/radio-monkey-online-radio-fm-app'}
                      link={"https://play.google.com/store/apps/details?id=radio.fm.mytunner.gaana.liveradio.radiostation.pocketfm&hl=en_IN&gl=US"}
                      name={"Radio Monkey"} 
                      image={"/images/icons/radio_new_icon_rm_512-33.png"} 
                      text="Radio FM is the finest app to listen to FM, AM, and other live radio stations. Explore more than 200 countries to enjoy multiple radio stations for best online radio experience
                      "/>
                       <AppLinks 
                      
                      page={"https://rareprob.com/apps/tweedle-free-ringtone-app"}
                      name={"Tweedle"} 
                      image={"/images/icons/launcher icon.png"} 
                      text="Tweedle is the finest app for prominent ringtones for your android device. You can explore variety of features like, Lo-Fi, bollywood, Pop, and more
                      "/>
           </Slider>
   
   
          
       </div>
     )
   }
   
   export default React.memo(AllApps)
import React from 'react'

const StackBall = () => {
    return (
      <div className="bg-stack-pattern stackBall h-[390px] md:h-[370px]  bg-center relative bg-no-repeat bg-cover rounded-3xl mt-10 flex md:flex-row flex-col items-center justify-start px-2 md:px-10">
        <div className='bg-gray-800 bg-opacity-50 z-10 absolute w-full h-full '>
  
        </div>
      <img  src="/images/Group 36@2x.png" alt="Stack Ball" className='w-44 md:w-72 relative z-20 p-4 md:p-10 bg-black bg-opacity-30 rounded-b-2xl md:rounded-t-2xl mt-0 md:mt-16 object-fill h-56 md:h-5/6'/>
       <div className='h-72 w-64 md:w-96 relative z-20  p-4  flex flex-col justify-center space-y-2 md:space-y-8 px-2 md:px-0 ml-0 md:ml-10 mt-0 md:mt-10'>
           <h1 className='font-bold text-xl text-center md:text-left md:text-4xl'>Stack Ball</h1>
           <p className='text-sm md:text-lg text-center md:text-left'>Smash the stack! A fun 3D stack game to level up your gaming experience</p>
         
              <button onClick={()=>{
                // window.stackBallDiv.onclick()
                window.location.href = 'https://play.rareprobgames.com/stackball/index.html';
  
              }} className='stackBall bg-gradient-to-r  from-[#006FCE] to-[#0058BF] w-44 md:w-72 mx-auto md:ml-0 p-2 md:p-4 px-0 md:px-10 rounded-xl md:rounded-[50px] text-xl md:text-4xl font-extrabold'>
                Play Now
              </button>
       </div>
   </div>
    )
  }

export default StackBall
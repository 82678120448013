import React, { useEffect } from 'react'
import './games.scss'

const GamesPage = () => {

useEffect(()=>{
  window.aman.onclick()
    import('./game.js')
    .then(({ moduleA }) => {
        moduleA.createBoard()
      // Use moduleA
    })
    .catch(err => {
      // Handle failure
    });
},[])

  return (
   < >
  
   <div className='board'></div>
<div className='clone'>
  
  <div className='face'></div>
  <div className='face'></div>
  <div className='face'></div>
  <div className='face'></div>
  <div className='face'></div>
  <div className='face'></div>
</div>

<div className='bg-red-900 absolute md:w-full md:h-screen w-[1200px] h-[1200px]'>

</div>

<div className='overlay hidden'>
  <div className='gameover'>
 
    <p>YOU WON!</p>
    <button className='reset'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><path d="M485.8 113.9L349.3 250.5c-3.5 3.5-3.5 9.1 0 12.6l136.6 136.6c5.6 5.6 15.2 1.6 15.2-6.3V120.2c-.1-7.9-9.7-11.9-15.3-6.3z" fill="#fff"/><path d="M500 194.8V317c125.9 0 228 102.1 228 228S625.9 773 500 773v121.8c193.3 0 350-156.7 350-350s-156.7-350-350-350zM272 545c0-55.1 19.6-105.7 52.1-145.1 6.5-7.9 5.9-19.5-1.4-26.8l-58.3-58.3c-8.2-8.2-21.7-7.7-29.3 1-53 61.5-85.1 141.5-85.1 229 0 193.3 156.7 350 350 350V773c-125.9 0-228-102.1-228-228z" fill="#fff"/></svg>
    </button>
  </div>
</div>

<audio preload="auto" className="audio-win" >
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/dino-win.mp3"/>
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/dino-win.ogg"/>
</audio>
<audio preload="auto" className="audio-ahahah" >
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-ahahah.mp3"/>
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-ahahah.ogg"/>
</audio>
<audio preload="auto" className="audio-dino" >
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audo-dino.mp3"/>
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-dino.ogg"/>
</audio>
<audio preload="auto" className="audio-egg" >
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-egg.mp3"/>
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-egg.ogg"/>
</audio>
<audio preload="auto" className="audio-erupt" >
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-erupt.mp3"/>
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-erupt.ogg"/>
</audio>
<audio preload="auto" className="audio-ptero" >
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-ptero.mp3"/>
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-ptero.ogg"/>
</audio>
<audio preload="auto" className="audio-tri" >
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-tri.mp3"/>
  <source src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/62105/audio-tri.ogg"/>
</audio>



   </>
  )
}

export default GamesPage